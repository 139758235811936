<template>
<div class="grid-cols-2" style="background: #33414E;">
    <div class="items-center space-x-4 mb-5">
      <div>
        <div class="flex" style="height:60px;background:#85c875;color:#fff;justify-content:center">
          <div>
            <p class="titleHeader" style="line-height:30px;font-family:Lato;font-size:33px;font-weight:bold;letter-spacing: 4px;margin-top:0;margin-bottom:0;padding-top:7px;">
            FONCABSA
          </p>
          <p class="titleMobil" style="line-height:30px;font-family:Lato;font-size:33px;font-weight:bold;letter-spacing: 4px;margin-top:0;margin-bottom:0;padding-top:16px;">
            F
          </p>
          <div style="font-size:12px;text-align:center;font-family:Lato;">
            <p class="subTitleHeader">Sistema Financiero Colaborativo</p>
          </div>
          </div>
        </div>
        <div class="py-5">
        
          <img v-show="load" src="@/assets/Analyst/profile.png" class="w-10 h-10 md:w-16 md:h-16 rounded-full mx-auto" />
          <div v-show="!load" class="animate-pulse rounded-full bg-white h-10 w-10 md:h-16 md:w-16 mx-auto"></div>
            
          <div v-show="load">
            <div class="grid grid-cols-1 mt-5">
              <div class="mb-3">
                <div class="flex">
                  <p class="w-full text-white text-xs md:text-sm ml-2 md:ml-4">Nombre de usuario</p>
                  <div class="w-full dropdown text-right mx-2 md:mx-5">
                    <span><i class="arrow down"></i></span><br>
                    <div class="dropdown-content left-5">
                        <ul class="">
                          <li style="padding:7px 10px 7px 10px;border-bottom:1px solid #85C875">
                            <img src="https://icongr.am/entypo/key.svg?size=82&color=000c6b" style ="float:left;width:20px;height:20px;margin-right:5px;" alt="">
                            <a href="#" class="font-light text-black text-base font-weight-md">Cambiar Contraseña</a>
                          </li>
                          <li style="padding:7px 10px 7px 10px;">
                            <img src="https://icongr.am/entypo/camera.svg?size=82&color=000c6b" style ="float:left;width:20px;height:20px;margin-right:5px;" alt="">
                            <a href="#" class="font-light text-black text-base font-weight-md">Cambiar Imagen de Perfil</a>
                          </li>
                        </ul>
                      </div>
                  </div> 
                </div>
                
                <p class="text-white text-xs md:text-sm ml-2 md:ml-4">{{fullName}}</p>
              </div>
              <div class="mb-3">
                <p class="text-white text-xs md:text-sm ml-2 md:ml-4">Puesto</p>
                <p class="text-white text-xs md:text-sm ml-2 md:ml-4">{{role}}</p>
              </div>
            </div> 
          </div>

          <div v-show="!load">
            <div class="p-4 max-w-sm w-full mx-auto">
              <div class="animate-pulse flex space-x-4">
                <div class="flex-1 space-y-6 py-1">
                  <div class="space-y-3">
                    <div class="grid grid-cols-3 gap-4">
                      <div class="h-2 bg-white rounded col-span-1"></div>
                    </div>                    
                    <div class="h-2 bg-white rounded"></div>
                  </div>

                  <div class="space-y-3">
                    <div class="grid grid-cols-3 gap-4">
                      <div class="h-2 bg-white rounded col-span-1"></div>
                    </div>                    
                    <div class="h-2 bg-white rounded"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ul class="space-y-2 text-sm accordion-menu">
            <li class="hover:bg-panelHover focus:bg-panelHover focus:shadow-outline" style="">
              <router-link to="/analyst/principal" class="md:flex md:items-center md:ml-5 space-x-3 text-gray-700 p-2 rounded-md font-medium">
                <span class="text-gray-600">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="margin:0 auto;"><path d="M20 9v11a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V9" /><path d="M9 22V12h6v10M2 10.6L12 2l10 8.6" /></svg>
                </span>
                <div class="dropdownlink">
                  <span class="titleMenu">Inicio</span>
                  <i class="fa fa-chevron-down" aria-hidden="true"></i>
                </div>
              </router-link>
            </li>
            <li class="hover:bg-panelHover focus:bg-panelHover focus:shadow-outline" style="">
              <router-link :to="`/${account}/captura-asistida/seccion/uno`" class="md:flex md:items-center md:ml-5 space-x-3 text-gray-700 p-2 rounded-md font-medium">
                <span class="text-gray-600">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="margin:0 auto;"><path d="M14 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V8l-6-6z"/><path d="M14 3v5h5M12 18v-6M9 15h6"/></svg>
                </span>
                <div class="dropdownlink">
                  <span class="titleMenu">Inscripción Asistida</span>
                  <i class="fa fa-chevron-down" aria-hidden="true"></i>
                </div>
              </router-link>
            </li>
            <li class="hover:bg-panelHover focus:bg-panelHover focus:shadow-outline" style="">
              <div >
                <div class="dropdownlink md:ml-5" style="padding:0px !important">
                  <div class="md:flex md:items-center text-gray-700" style="flex-wrap:wrap;">
                  <span class="text-gray-600 p-2 rounded-md font-medium">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="margin:0 auto;"><path d="M14 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V8l-6-6z"/><path d="M14 3v5h5M16 13H8M16 17H8M10 9H8"/></svg>
                  </span>
                  <div class="dropdownlinkSubMenuItem">
                    <span class="titleMenu">Reportes</span>
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                  </div>
                </div>
                </div>
                
                <ul class="submenuItems">
                  <li style="">  <router-link to="/analyst/reports/carga-itbnks" >Carga ITBNKS</router-link></li>
                </ul>
              </div>
            </li>
            <li class="hover:bg-panelHover focus:bg-panelHover focus:shadow-outline" style="">
              <router-link :to="`/${roleSeccion}/referralhistory`" class="md:flex md:items-center md:ml-5 space-x-3 text-gray-700 p-2 rounded-md font-medium">
                <span class="text-gray-600">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="margin:0 auto;"><path d="M14 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V8l-6-6z"/><path d="M14 3v5h5M16 13H8M16 17H8M10 9H8"/></svg>
                </span>
                <div class="dropdownlink">
                  <span class="titleMenu">Historial de Referidos</span>
                  <i class="fa fa-chevron-down" aria-hidden="true"></i>
                </div>
              </router-link>
            </li>
            <li class="hover:bg-panelHover focus:bg-panelHover focus:shadow-outline" style="">
              <a v-on:click="logout" href="#" class="md:flex md:items-center md:ml-5 space-x-3 text-gray-700 p-2 rounded-md font-medium">
                <span class="text-gray-600">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" style="margin:0 auto;"><path d="M10 3H6a2 2 0 0 0-2 2v14c0 1.1.9 2 2 2h4M16 17l5-5-5-5M19.8 12H9"/></svg>
                </span>
                <div class="dropdownlink">
                  <span class="titleMenu">Cerrar Sesión</span> 
                  <i class="fa fa-chevron-down" aria-hidden="true"></i>
                </div>
              </a>
            </li>
        </ul>
    </div>
  </div>
</div>
</template>

<script>
import "@/assets/css/tailwind.css";
import {getTokenDecoden} from "@/helpers/tokenauth";
import {useRouter} from "vue-router";

export default {
    name: 'MenuLateral',
    props: {
    },
    data() {
      return {
        fullName: "",
        role: "",
        accountType: "",
        account:"",
        load: false
      }
    },
    setup(){
      const router = useRouter();
      return { router }
    },
    mounted() {
        this.loadInfo();
        $(function() {
            var Accordion = function(el, multiple) {
                this.el = el || {};
                // more then one submenu open?
                this.multiple = multiple || false;
                
                var dropdownlink = this.el.find('.dropdownlink');
                dropdownlink.on('click',
                                { el: this.el, multiple: this.multiple },
                                this.dropdown);
            };
            
            Accordion.prototype.dropdown = function(e) {
                var $el = e.data.el,
                    $this = $(this),
                    //this is the ul.submenuItems
                    $next = $this.next();
                
                $next.slideToggle();
                $this.parent().toggleClass('open');
                
                if(!e.data.multiple) {
                //show only one menu at the same time
                    $('ul.space-y-2.text-sm.accordion-menu').find('.submenuItems').not($next).slideUp().parent().removeClass('open');
                }
            }
            
            var accordion = new Accordion($('.accordion-menu'), false);
        })
    },
    methods:{
        logout(){
            sessionStorage.clear();
            //localStorage.clear();
            // window.location.href = "/"
            this.router.push({path:"/"});
        },
        loadInfo() {
          var auth = getTokenDecoden();
          
          switch (auth.obj["role"]) {
            case "administrator":
              this.account = "admin";
              break;
            case "member":
              this.account = "cuenta";
              break;
            case "member_analyst":
              this.account = "analyst";
              break;
            case "cabsa_member":
              this.account = "cuenta";
              break;
            case "supervisor":
              this.account = "supervisor";
              break;
            case "loan_analyst":
              this.account = "analyst";
              break;
            case "enroller": 
              this.account="adviser";
            break;
          }
          
          this.accountType = auth.obj['role'];
          this.fullName = `${auth.obj.personnel_info["name"] == null ? "" : auth.obj.personnel_info["name"]} ${auth.obj.personnel_info["middle_name"] == null ? "" : auth.obj.personnel_info["middle_name"]} ${auth.obj.personnel_info["paternal_name"] == null ? "" : auth.obj.personnel_info["paternal_name"]} ${auth.obj.personnel_info["maternal_name"] == null ? "" : auth.obj.personnel_info["maternal_name"]}`;
          var list_names =  this.fullName.split(" ")
          var name = "";
          for (var i = 0; i < list_names.length; i+=1) {
            name += this.upperCaseFirst(list_names[i])+" ";
          }
          this.fullName = name;

          this.role = this.getTypeRole(auth.obj["role"]);

          setTimeout(() => {
            this.load = true;
          }, 1000);
          
        },
        upperCaseFirst(str){
            if (str != "undefined" && str != null && str != "") {
              return str.charAt(0).toUpperCase() + str.substring(1).toLowerCase();
            }
            return ""
        },
        getTypeRole(type){
          switch(type) {
            case "member_analyst":
              return "Analista de clientes"
            case "loan_analyst":
              return "Analista de prestamos"
            default:
              return ""
          }
        },
    }
}
</script>
<style scoped>
.avatar {
  padding: 2em 0.5em;
  text-align: center;
}
.avatar img {
  margin:0 auto;
  width: 22%;
  height: 45px;
  border-radius: 50%;
  overflow: hidden;
  
}
.avatar h2 {
  font-weight: normal;
  margin-bottom: 0;
}
span {
  color: #fff;
  font-family:"Lato";
}
ul li a {
  text-decoration:none;
}
.dropdown-content {
  padding: 0;
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  text-align: left;
  min-width: 230px;
  border-width: 1px;
  border-style: solid;
  border-color:#85C875;
  box-shadow: 0 0 5px #9ecaed;
  z-index: 1;
}
.dropdown:hover .dropdown-content {
  display: block;
}

.accordion-menu {
  width: 100%;
  max-width: 350px;
  background: #33414E;
  border-radius: 4px;
}

.accordion-menu li a{
    text-decoration: none;
}
.accordion-menu li.open .dropdownlink {
  color: #727272;
}

.accordion-menu li.open .dropdownlink .fa-chevron-down {
    transform: rotate(180deg);
}

.accordion-menu li:last-child .dropdownlink {
  border-bottom: 0;
}
.dropdownlink {
  cursor: pointer;
  display: block;
  padding: 11px 11px 11px 5px;
  font-size: 15px;
  color: #FFF;
  position: relative;
  transition: all 0.4s ease-out;
}

.dropdownlinkSubMenuItem {
  cursor: pointer;
  display: block;
  padding: 15px 15px 15px 8px;
  font-size: 15px;
  color: #FFF;
  position: relative;
  transition: all 0.4s ease-out;
}

.dropdownlink i {
    position: absolute;
    top: 17px;
    left: 16px;
}

.dropdownlink .fa-chevron-down {
    right: 12px;
    left: auto;
}

.submenuItems {
  display: none;
  background: #FFF;
  width: 100%;
}

.submenuItems li a {
  text-decoration: none;
}

.submenuItems a {
  display: block;
  color: #174a84;
  font-size: 12px;
  padding: 12px 12px 12px 50px;
  transition: all 0.4s ease-out;
}

.arrow {
  border: solid #fff;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}
.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.titleHeader {
  display: block;
}
.titleMobil {
  display: none;
}
.searchBoxContent {
  display: none;
}
@media screen and (max-width: 1280px) {
  .avatar img {
    margin:0 auto;
    width:20%;
    height:50px;
  }
}
@media screen and (max-width: 1200px) {
  .avatar img {
    margin:0 auto;
    width:25%;
    height:50px;
  }
}
@media screen and (max-width: 1000px) {
  .avatar img {
    margin:0 auto;
    width:30%;
    height:50px;
  }
}
@media screen and (max-width: 640px) {
  .avatar img {
    margin:0 auto;
    width:50%;
    height:40px;
  }
}
@media screen and (max-width: 779px) {
  .titleHeader {
    display: none !important;
  }
  .titleMobil {
    display: block;
  }
  .titleMenu {
    display: none;
  }
  .subHeader {
    display: none;
  }
  .subTitleHeader {
    display: none;
  }
  .dropdownlink {
    padding: 5px 11px 5px 5px;
  }
  .dropdownlinkSubMenuItem {
    padding: 5px 11px 5px 5px;
  }
  .submenuItems a {
    padding: 12px;
    text-align: center;
  }
  .searchBoxContent {
    margin-top:20px;
    display: block;
    margin-bottom: 80px;
  }
}
@media screen and (max-width: 499px) {
  .avatar img {
    margin:0 auto;
    width:60%;
    height:40px;
  }
}

</style>