<template>
<div class="flex flex-col ">
  <nav class="" style="height:80px;background:#fff;border-bottom: 2px solid #cae0f9">
    <div class="header_container">
    </div>
  </nav>
</div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
    };
  },
  methods: {
  }
};
</script>
<style scoped>
.header {
  background:red;
  height: 60px;
}
.header_container {
  
}
.img_container {
  
}
.img_menu {
  
}
@screen sm {
  
}
</style>
